@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
* {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}

h4, h5 {
	margin: 0;
}

.flex {
	display: flex;
}

.flex-direction-column {
	flex-direction: column;
}

.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.flex-end {
	justify-content: flex-end;
}

.flex-no-shrink {
	flex-shrink: 0;
}

.space-between {
	justify-content: space-between;
}

.halfwidth {
	width: 50%;
}

.fullwidth {
	width: 100%;
}

.mb-4 {
	margin-bottom: 4px;
}
.mb-12 {
	margin-bottom: 12px;
}
.mr-4 {
	margin-right: 4px;
}
.ml-4 {
	margin-left: 4px;
}

.m-8 {
	margin: 8px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mt-8 {
	margin-top: 8px;
}
.mr-8 {
	margin-right: 8px;
}
.ml-8 {
	margin-left: 8px;
}
.mr-16 {
	margin-right: 16px;
}
.ml-16 {
	margin-left: 16px;
}
.mb-16 {
	margin-bottom: 16px;
}
.ml-16 {
	margin-left: 16px;
}
.ml-24 {
	margin-left: 24px;
}

.p-8 {
	padding: 8px;
}
.py-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}
.p-16 {
	padding: 16px;
}
.px-16 {
	padding-left: 16px;
}
.pr-16 {
	padding-right: 16px;
}

.cta {
	font-size: 18px;
	color: #4a4a4a;

	height: 32px;

	display: flex;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;

	border-radius: 5px;
	border: none;
	padding: 8px;

	&:hover {
		cursor: pointer;
		background-color: darken(#fff, 5%);
	}
	&.cta--secondary {
		font-size: 14px;
		background-color: #f9f9f9;
		&:hover {
			background-color: darken(#f9f9f9, 5%);
		}
	}

	&.cta--minimal {
		font-size: 14px;
		background-color: transparent;
		&:hover {
			background-color: #fff;
		}
	}
	&.cta--blue {
		color: #fff;
		background-color: #4abeff;

		&:hover {
			cursor: pointer;
			background-color: darken(#4abeff, 5%);
		}
	}
	&:disabled {
		background-color: #ccc;
		&:hover {
			cursor: not-allowed;
			background-color: #ccc;
		}
	}
}

.background-f9f9f9 {
	background-color: #f9f9f9;
}

.background-f1f1f1 {
	background-color: #f1f1f1;
}

.borderRadius-5 {
	border-radius: 5px;
}

.textOnlyButton {
	color: #404040;
	&:hover{
		color: lighten(#404040, 20%);
		cursor: pointer;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.arrow-down {
	width: 0; 
	height: 0; 
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	
	border-top: 10px solid #c0c0c0;
}
.arrow-up {
	width: 0; 
	height: 0; 
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	
	border-bottom: 10px solid #c0c0c0;
}
.arrow-left {
	width: 0; 
	height: 0; 
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent; 
	
	border-right: 10px solid #c0c0c0; 
}

.remove {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	flex-shrink: 0;
	&:hover {
		background-color: #ccc;
		cursor: pointer;
	}
}

.ebi__input {
	border: none;
	background-color: #fafafa;
	border-radius: 5px;
	padding: 4px 8px;
	box-sizing: border-box;
	height: 38px;
	width: 100%;
	max-width: 700px;

	&.ebi__input--white {
		background-color: #fff;
		border: 1px solid #ccc;
	}

	&:focus {
		outline: none;
		border: 1px solid #ccc;
	}
}

.ebi__textArea {
	border: none;
	background-color: #fafafa;
	border-radius: 5px;
	padding: 8px;
	box-sizing: border-box;
	height: 320px;
	width: 100%;
	max-width: 700px;

	&:focus {
		outline: none;
		border: 1px solid #ccc;
		border-radius: 5px;
	}
}
