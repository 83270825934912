.signin-panel-box {
	padding: 16px;
	align-items: center;
	justify-content: space-between;
	background-color: #f9f9f9;
	margin: 20px;
	border: 2px solid #c5c5c5;
	border-radius: 5px;

	p {
		width: 100%;
		text-align: center;

		.signin-button {
			font-size: 14px;
			padding: 8px;
			color: #404040;
			border: 1px solid #404040;
			background-color: #fff;
			border-radius: 5px;
			&:hover {
				cursor: pointer;
				background-color: darken(#fff, 5%);
			}
		}
	}
}
