.dashboardRegionLinks {
	.dashboardRegionLinks__container:not(:first-child) {
		border-top: 1px solid #eee;
	}
}

.dashboardVisibilityToggle:hover, .dashboardVisibilityToggle > input {
	cursor: pointer;
}

div.dashboardDeployments {
	padding: 4px;
	font-weight: 300;
}
