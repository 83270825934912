.createDashboard {
	padding-top: 16px;
	padding-bottom: 16px;
	.createDashboard__borderedColumn {
		border-right: 1px solid #eee;
	}

	.createDashboard__ruleSets, .createDashboard__filters {
		.MuiCheckbox-colorSecondary {
			color: #404040;
			&.Mui-checked {
				color: #4abeff;
			}
			&:hover {
				background-color: rgba(74, 190, 255, 0.1);
			}
		}
		.MuiFormControlLabel-label {
			font-weight: 300;
		}
		> :not(:first-child) {
			margin-top: 16px;
			padding-top: 16px;
			border-top: 1px solid #eee;
		}
	}
}
