.header {
	margin: -8px -8px 8px -8px;
	background-color: #f9f9f9;

	.header__appTitleBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;

		.header__appTitleBar__LHS {
			background-image: url('../../accelo_logo.svg');
			background-repeat: no-repeat;
			text-indent: 150px;
			margin: 0;
			font-size: 24px;

			&:hover {
				cursor: pointer;
			}
		}

		.header__appTitleBar__RHS__logout {
			font-size: 14px;
			padding: 8px;
			color: #404040;
			border: 1px solid #404040;
			background-color: #fff;
			border-radius: 5px;

			&:hover {
				cursor: pointer;
				background-color: darken(#fff, 5%);
			}

			&.header__appTitleBar__RHS__logout--disabled {
				background-color: #ccc;
				&:hover {
					cursor: not-allowed;
					background-color: #ccc;
				}
			}
		}
	}

	.header__navBar {
		display: flex;
		align-items: center;
		border: 2px solid #eee;
		border-width: 2px 0px;
		padding: 8px 16px;

		.header__navBar__LHS__title {
			font-size: 24px;
			color: #444;
			margin: 0;
			font-weight: normal;
			margin-right: 16px;
		}
		.header__navBar__LHS__button {
			font-size: 18px;
			color: #fff;

			height: 32px;

			display: flex;
			justify-content: center;
			align-items: center;

			box-sizing: border-box;

			border-radius: 5px;
			border: none;
			background-color: #4abeff;
			padding: 8px;

			&:hover {
				cursor: pointer;
				background-color: darken(#4abeff, 5%);
			}

			&.header__navBar__LHS__button--disabled {
				background-color: #ccc;
				&:hover {
					cursor: not-allowed;
					background-color: #ccc;
				}
			}
		}
	}
}
