.loadingOverlay {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	.loadingOverlay__spinner {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;

		div {
			box-sizing: border-box;
			display: block;
			position: fixed;
			width: 64px;
			height: 64px;
			margin: 8px;
			border: 8px solid #fff;
			border-radius: 50%;
			animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #fff transparent transparent transparent;
			&:nth-child(1) {
				animation-delay: -0.45s;
			}
			&:nth-child(2) {
				animation-delay: -0.3s;
			}
			&:nth-child(3) {
				animation-delay: -0.15s;
			}

			@keyframes spinner {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}
