.listRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F3F3F3;
	padding: 8px 16px;

	&:hover {
		transition: all 0.5s;
		cursor: pointer;
		background-color: darken(#F3F3F3, 5%);
	}

	.listRow__title {
		font-size: 18px;
		width: 300px;
	}
}