.dashboard {
	font-size: 16px;
	margin-bottom: 2px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: #fff;

	.dashboardRow__title {
		text-transform: lowercase;
		&:first-letter {
			text-transform: uppercase;
		}
		width: 300px;
	}

	.dashboardRow__status {
		text-transform: lowercase;
		&:first-letter {
			text-transform: uppercase;
		}
		width: 200px;
	}

	&:hover {
		.dashboard__RHS {
			opacity: 1;
		}
	}

	.dashboard__RHS {
		opacity: 0;
		transition: opacity 0.1s;
		display: flex;
		align-items: center;

		.dashboard__RHS__edit {
			height: 24px;
			font-size: 14px;

			display: flex;
			justify-content: center;
			align-items: center;

			box-sizing: border-box;

			border-radius: 5px;
		}

		.dashboard__RHS__button {
			color: #fff;

			font-size: 14px;

			display: flex;
			justify-content: center;
			align-items: center;

			box-sizing: border-box;

			border-radius: 5px;

			padding: 8px;
			border: none;

			&.dashboard__RHS__button--publish {
				background-color: #21df21;

				&:hover {
					cursor: pointer;
					background-color: darken(#00ff00, 5%);
				}
			}

			&.dashboard__RHS__button--unpublish {
				background-color: #df8621;
				&:hover {
					cursor: pointer;
					background-color: darken(#ffbb00, 5%);
				}
			}

			&.dashboard__RHS__button--disabled {
				background-color: #ccc;
				&:hover {
					cursor: not-allowed;
					background-color: #ccc;
				}
			}
		}
	}
}
