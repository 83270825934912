.category {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F3F3F3;
	padding: 8px 16px;

	&:hover {
		transition: all 0.5s;
		cursor: pointer;
		background-color: darken(#F3F3F3, 5%);
	}

	.categoryRow__status {
		text-transform: lowercase;
		&:first-letter {
			text-transform: uppercase;
		}
	}

	.category__LHS__title {
		font-size: 18px;
		width: 300px;
	}

	.category__RHS {
		display: flex;
		align-items: center;
	}
}
